import React from 'react';

const GOOGLE_AUTH_PATH = '/auth/google_oauth2'
const FACEBOOK_AUTH_PATH = '/auth/facebook'

export function GoogleLoginLink(props){

    return (
        <a href={GOOGLE_AUTH_PATH}>
            <div className="login-button google">
                Continue with Google
            </div>
        </a>
    )
}

export function FacebookLoginLink(props){
    return (
        <a href={FACEBOOK_AUTH_PATH}>
            <div className="login-button facebook">
                Continue with Facebook
            </div>
        </a>
    )
}
import React from 'react';
import {Form, InputField, Button, Row, Column} from "../forms/Form";
import {FetchJSON} from "../../functions/api_tools";
import autobind from "../../functions/autobind";

export default class LinkSSOForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {};
    }

    link(e){
        e.preventDefault();
        this.props.linkClicked();
    }

    create(e){
        e.preventDefault();
        this.props.registerClicked();
    }


    render() {
        return (
            <Form className="login-section-container login-form">
                <p><b>This is your first time signing into Variable with {this.props.provider}.</b> <br/></p>
                {this.props.emailExists ?
                    <p>A Variable account exists matching your email address.  Would you like to link it to your {this.props.provider} account?</p>
                    :
                    <p>Do you have an existing account with Variable you'd like to link with your {this.props.provider} account?</p>
                }
                <a onClick={this.link}><div className="login-button generic">Yes, Link Existing Account</div></a>
                <a onClick={this.create}><div className="login-button generic">No, Create New Account</div></a>
            </Form>
        );
    }
}

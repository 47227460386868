import React from 'react';
import { Form, InputField, Button, Row, Column } from "../forms/Form";
import { FetchJSON } from "../../functions/api_tools";
import autobind from "../../functions/autobind";
import { Box, Link, Typography } from '@mui/material';

export default class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            user_data: { email: '', password: '', otp_attempt: '' },
            error_message: '',
            show_otp: false,
            resended: false,
        };
    }

    setUserData(key, value) {
        this.setState(prev => {
            prev.user_data[key] = value;
            return prev;
        })
    }

    handleEmailChange = this.setUserData.bind(this, 'email');
    handlePasswordChange = this.setUserData.bind(this, 'password');
    handleOtpChange = this.setUserData.bind(this, 'otp_attempt')

    handleSubmit(e) {
        e.preventDefault();

        if (this.state.user_data.email === '' || this.state.user_data.password === '') {
            this.setState({ error_message: 'Please Enter Email and Password' });
            return;
        }

        if (this.state.show_otp && this.state.user_data.otp_attempt === '') {
            this.setState({ error_message: 'Please Enter Authentication Code' });
            return;
        }

        let url = this.props.loginContext === 'sso_link' ? '/sso/login_to_link_create' : '/login'

        this.setState({ error_message: '' });
        FetchJSON(url, { user: this.state.user_data }).then(response => response.json()).then(data => {
            if (data.success) {
                if (data.otp_required) {
                    this.setState({ show_otp: true });
                    window.__loginDataForTest = data;
                } else {
                    this.props.handleLogin(data.location);
                }
            } else {
                this.setState({ error_message: this.state.show_otp ? 'Invalid Authentication Code' : 'Invalid Email or Password' });
            }
        })
    }

    resend_code() {
        this.setState({ error_message: '' });
        FetchJSON('/login', { user: { email: this.state.user_data.email, password: this.state.user_data.password } }).then(response => response.json()).then(data => {
            if (data.success) {
                this.setState({ resended: true });
            } else {
                this.setState({ error_message: 'Error Sending Code' });
            }
        })
    }

    // startCooloff() {
    //     this.setState({ resend_cooloff: 60 });
    //     this.cooloff_interval = setInterval(() => {
    //         this.setState(prev => {
    //             prev.resend_cooloff = prev.resend_cooloff - 1;
    //             return prev;
    //         })
    //         if (this.state.resend_cooloff <= 0) {
    //             clearInterval(this.cooloff_interval);
    //         }
    //     }, 1000);
    // }

    render() {

        return (
            <Form className="login-section-container login-form" onSubmit={this.handleSubmit}>
                <Row><Column>
                    <InputField key="email" type="email" label="Email" readOnly={this.state.show_otp} value={this.state.user_data.email} onChange={this.handleEmailChange} />
                </Column></Row>
                <Row><Column>
                    <InputField type="password" key="password" label="Password" readOnly={this.state.show_otp} value={this.state.user_data.password} onChange={this.handlePasswordChange} />
                </Column></Row>

                {this.state.show_otp && <>
                    <Row style={{ marginBottom: 0 }}>
                        <Column>
                            <InputField type="text" key="otp_attempt" label="Authentication Code" value={this.state.user_data.otp_attempt} onChange={this.handleOtpChange} />
                        </Column>
                        {/* <Column style={{ flex: 1, marginTop: 24, marginLeft: 10 }}>
                        {this.state.resend_cooloff < 1 && <Button type="button" className="button secondary-button" value="Resend" style={{ padding: '0 5px' }} onClick={this.resend_code} />}
                        {this.state.resend_cooloff > 0 && <div style={{ color: '#999' }}>Resend in {this.state.resend_cooloff}s</div>}
                    </Column> */}
                    </Row>
                    <Box mb={3}>
                        <Typography pb={1} component={'span'}>You’ve been emailed an authentication code, valid for 10 minutes.<br /></Typography>
                        {!this.state.resended && <Typography component={'span'}>Didn’t get it? <a onClick={this.resend_code}>Resend code</a></Typography>}
                        {this.state.resended && <Typography component={'span'}>Code resent successfully.</Typography>}
                    </Box>
                </>}

                <Row className="checkbox">
                    <Column>
                        <InputField key="remember" type="checkbox" label="Remember Me" labelPosition="after" />
                    </Column>

                    <div className="form-column forgot-password">
                        <a onClick={this.props.forgotPasswordClicked}>Forgot Password?</a>
                    </div>
                </Row>

                {this.state.error_message && <Row><Column><div className='login-error'>{this.state.error_message}</div></Column></Row>}

                <div className="actions">
                    <Button className="button submit" value="Log In" />
                </div>

                {/* {this.props.loginContext === 'sso_link' ?
                    <></>
                    :
                    <div className="login-link login-link-block" style={{ margin_top: '10px' }}>
                        <a onClick={this.props.registerClicked}>Register For Free</a>
                    </div>

                } */}

            </Form>
        );
    }
}

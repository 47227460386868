import React,{useState,useEffect} from 'react';
import {Wrap} from "../forms/LabeledInput";
import {FetchHTML} from "../../functions/api_tools";
import ScrollDialog from "./ScrollDialog";

const TermsCheck = props => {
    const [termsAccepted,setTermsAccepted] = useState(false);
    const [displayTerms,setDisplayTerms] = useState(false);
    const [content, setContent] = useState("");

    useEffect(() => {
        FetchHTML(props.termsURL).then(response => response.text()).then(text => setContent(text))
    },[]);


    const toggleChecked = e => {
        props.onChecked(!termsAccepted);
        setTermsAccepted(!termsAccepted);
    }

    const dialogClosed = () => {
        setDisplayTerms(false);
    }

    const labelText = () => {
        const re = /(?<beforeLink>.*)\[(?<link>.*)\](?<afterLink>.*)/i;
        const labelMatches = props.label.match(re);
        return <span>{labelMatches.groups.beforeLink}<a onClick={() => setDisplayTerms(true)}>{labelMatches.groups.link}</a>{labelMatches.groups.afterLink}</span>
    }

    return (
        <>
            {displayTerms ?
                <ScrollDialog title={props.title} onClosed={dialogClosed}>
                    <div dangerouslySetInnerHTML={{__html:content}} />
                </ScrollDialog>
                :
                <Wrap layout="single-checkbox">
                    <input type="checkbox" defaultChecked={termsAccepted} onChange={toggleChecked} required={true} />
                    <label className="checkbox-label">{labelText()}</label>
                </Wrap>
            }
        </>
    )

}


const TermsOfUse = props => {
    return <TermsCheck title="Terms of Use" label="I have read and agree to the [Terms of Use]" termsURL="/terms" onChecked={props.onChecked} />
}

const PrivacyPolicy = props => {
    return <TermsCheck title="Privacy Policy" label="I have read and understand the [Privacy Policy]" termsURL="/privacy" onChecked={props.onChecked} />
}

export {TermsOfUse,PrivacyPolicy};

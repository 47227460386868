import React from 'react';

function LandingBackground(props){
 return (
     <div className={"landing-background " + props.position + (props.active ? ' active-background' : '')}>
         <div className="landing-background-content">
             <div className="landing-background-title">
                 {props.title}
             </div>
         </div>
     </div>
 )
}

export default class LandingBackgrounds extends React.Component {

    constructor(props) {
        super(props);
        this.state = {active: 0};
    }

    componentDidMount() {
        setTimeout(this.setActive.bind(this),4000);
    }

    setActive(){
        this.setState({active: (this.state.active + 1) % 4 });
        setTimeout(this.setActive.bind(this),4000);
    }


    render() {

        return (
            <>
                <LandingBackground position="top-left" title="Employee Development" active={this.state.active === 0}/>
                <LandingBackground position="top-right" title="Project Management" active={this.state.active === 1}/>
                <LandingBackground position="bottom-left" title="Training and Certification Verification" active={this.state.active === 2}/>
                <LandingBackground position="bottom-right" title="Cross-Organization Requirements Management" active={this.state.active === 3}/>
            </>
        );
    }
}

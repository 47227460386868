import React, {useEffect, useState} from "react";
import Banner from "./Banner";

const Banners = props => {
    const [isDismiss, setIsDismiss] = useState({});

    useEffect(() => {
        const storedBanners = JSON.parse(window.localStorage.getItem("banners"));
        if (!storedBanners) return;

        props.banners.length > 0 && props.banners.map(banner => {
            Object.keys(storedBanners).length > 0 && Object.keys(storedBanners).map(k => {
                if (Number(k) === Number(banner.id)) {
                    setIsDismiss(storedBanners)
                }
            })
        })
    },[])

    const dismiss = (id) => {
        const storedBanners = window.localStorage.getItem("banners");
        let obj = {};

        if (!storedBanners) {
            obj[id] = true
        } else {
            obj = JSON.parse(storedBanners)
            obj[id] = true
        }

        window.localStorage.setItem("banners", JSON.stringify(obj))
        setIsDismiss(obj);
    }

    return (
        props.banners.length > 0 && props.banners.map((banner, idx) =>
            !isDismiss[banner.id] && <Banner key={idx} {...banner} discard={dismiss} type={banner.banner_type} />
        )
    )
};

export default Banners;

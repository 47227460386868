import React from 'react';
import RegistrationForm from "./RegistrationForm";
import Animation from "../../classes/Animation";
import WelcomeForm from "./WelcomeForm";
import ResetPasswordRequestForm from "./ResetPasswordRequestForm";
import ResetPasswordForm from "./ResetPasswordForm";
import autobind from "../../functions/autobind";
import LoginForm from "./LoginForm";
import { Form } from "../forms/Form";
import LinkSSOForm from "./LinkSSOForm"
import { FetchJSON } from "../../functions/api_tools";

function MobileSpacer(props) {
    return <div className="login-spacer-mobile" />
}

let intro_sequence = [0, 90];
let swipe_away_sequence = [90, 102];
let swipe_appear_sequence = [102, 114];
let registration_sequences = [154, 202, 250, 290, 334];

export default class AccountForms extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {};
        this.loginCircle = React.createRef();
        this.state = { step: props.skipToStep || 'welcome', sequence_started: false, swipe_visible: true, login_circle_text: '' };
    }

    componentDidMount() {


    }

    swipeAway() {
        this.setState({ swipe_visible: false, sequence_started: true });
    }

    login(e) {
        if (e) { e.preventDefault() }
        this.swipeAway();
        this.setState({ step: 'login', login_circle_text: 'Login' });
    }

    loginToLink(e) {
        if (e) { e.preventDefault() }
        this.swipeAway();
        this.setState({ step: 'login_to_link', login_circle_text: 'Login' });
    }



    handleLogin(location) {
        this.setState({ step: 'logging_in', login_circle_text: 'Logging in...' });
        window.location = location;
    }

    register(e) {
        if (e) { e.preventDefault() }
        if (this.props.registrationApproved) {
            this.registrationStepChanged(0);
            this.setState({ step: 'register', swipe_visible: false, sequence_started: true, login_circle_text: 'Register for Free' });
        } else {
            this.swipeAway();
            this.setState({ step: 'registration_closed', swipe_visible: false, sequence_started: false, login_circle_text: 'Invite Only' });
        }



    }

    registrationStepChanged(step) {
        if (step === 3) {
            window.location = "/user/onboard"
        }
    }


    resetPasswordRequest(e) {
        e.preventDefault();
        this.swipeAway();
        this.setState({ step: 'reset_password_request', login_circle_text: 'Forgotten Password?' });
    }

    ssoRegisterClicked() {
        if (this.props.registrationApproved) {
            this.setState({ step: 'message', message: 'Completing registration....' });
            FetchJSON('/register/complete_sso_signup', undefined, 'GET').then(response => response.json()).then(data => {
                if (data.success) {
                    this.setState({ step: 'message', message: 'Signing you in.  Hold on....' });
                    window.location = data.location;
                } else {
                    this.setState({ step: 'message', message: 'Error processing registration.  Please try again later.' });
                }
            })
        } else {
            this.setState({ step: 'registration_closed', swipe_visible: false, sequence_started: false, login_circle_text: 'Invite Only' });
        }


    }

    contextMessage() {
        switch (this.props.context) {
            case 'location-scan':
                return "Track the locations where you have checked-in and be notified if you may have come into contact with COVID-19"
            case 'interaction-scan':
                return "Track your interactions be notified if you may have come into contact with COVID-19"
            default:
                return "Tracking, training, project management and organization all in one place."
        }
    }


    render() {

        return (
            <>
                <MobileSpacer />
                <div id="login-title" style={{ marginBottom: "0px", borderRadius: 0 }} className={"login-section-container" + (this.state.sequence_started ? ' sequence-started' : '')}>
                    <div className="login-info-container">
                        <div className="login-title" />
                        <div className="login-description">
                            {this.contextMessage()}
                        </div>
                    </div>
                </div>

                <MobileSpacer />



                {(() => {
                    switch (this.state.step) {
                        case 'welcome':
                            return <WelcomeForm login={this.login} register={this.register} />
                        case 'register':
                            return <RegistrationForm user={this.props.user} loginClicked={this.login} registrationStepChanged={this.registrationStepChanged} />
                        case 'login':
                            return <LoginForm registerClicked={this.register} forgotPasswordClicked={this.resetPasswordRequest} handleLogin={this.handleLogin} />
                        case 'login_to_link':
                            return <LoginForm loginContext="sso_link" forgotPasswordClicked={this.resetPasswordRequest} handleLogin={this.handleLogin} />
                        case 'reset_password_request':
                            return <ResetPasswordRequestForm registerClicked={this.register} loginClicked={this.login} />
                        case 'reset_password':
                            return <ResetPasswordForm registerClicked={this.register} loginClicked={this.login} />
                        case 'registration_closed':
                            return (
                                <Form className="login-section-container login-form">
                                    <p>Registration is by invitation only at this time.</p>
                                    <div className="login-link login-link-block" style={{ margin_top: '10px' }}>
                                        <a onClick={this.login}>Login</a>
                                    </div>
                                </Form>
                            )
                        case 'link_sso':
                            return <LinkSSOForm provider={this.props.ssoProvider} emailExists={this.props.emailExists} linkClicked={this.loginToLink} registerClicked={this.ssoRegisterClicked} />
                        case 'logging_in':
                            return null;
                        case 'message':
                            return (
                                <Form className="login-section-container login-form">
                                    <p>{this.state.message}</p>
                                </Form>
                            )
                        default:
                            return null;
                    }
                })()}



            </>
        );
    }
}

import React from 'react';
import autobind from "../../functions/autobind";


function ChecklistItem(props){
    return (
        <div className={["checklist-item", props.type, props.valid ? 'checked' : ''].join(' ')}>
            {props.text}
        </div>
    )
}


export default class PasswordChecklist extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {valid: false, criteria_validity: this.validity(props.password, props.passwordConfirmation)};
    }

    componentWillReceiveProps(nextProps) {
        this.setState(previous => {
            previous.criteria_validity =  this.validity(nextProps.password, nextProps.passwordConfirmation);
            if (previous.valid !== this.valid()){
                previous.valid = !previous.valid;
                this.props.passwordValidityChange(previous.valid);
            }
            return previous;
        })
    }

    validity(password, passwordConfirmation){
        return {
                length: (password.length >= 6),
                number: /\d/.test(password),
                uppercase: /[A-Z]/.test(password),
                special: /[^A-Za-z0-9\s]+/.test(password),
                match: password.length > 0 && password === passwordConfirmation
            };
    }

    valid(){
        return Object.values(this.state.criteria_validity).every(check => check);
    }

    render(){
        return (
            <div className="password-checklist">
                <ChecklistItem type="length" valid={this.state.criteria_validity.length} text="At least 6 characters" />
                <ChecklistItem type="uppercase" valid={this.state.criteria_validity.uppercase} text="Capital Letter" />
                <ChecklistItem type="number" valid={this.state.criteria_validity.number} text="Number" />
                <ChecklistItem type="special" valid={this.state.criteria_validity.special} text="Special Character" />
                <ChecklistItem type="match" valid={this.state.criteria_validity.match} text="Passwords Match" />
            </div>
        )
    }

}

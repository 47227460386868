import React from 'react';
import {Form, InputField, Button, Row, Column} from "../forms/Form";
import {FetchJSON,GetURLParam} from "../../functions/api_tools";
import autobind from "../../functions/autobind";
import PasswordChecklist from "./PasswordChecklist";

export default class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            success: false,
            valid: false,
            user_data: {password: '', password_confirmation: '', reset_password_token: GetURLParam('reset_password_token')},
            user_data_errors: {}
        };
    }

    componentDidMount() {
    }

    setUserData(key,value){
        this.setState(prev =>{
            prev.user_data[key] = value;
            return prev;
        })
    }

    handlePasswordChange = this.setUserData.bind(this,'password');
    handlePasswordConfirmationChange = this.setUserData.bind(this,'password_confirmation');

    handlePasswordValidityChange(valid){
        this.setState({valid: valid})
    }

    handleSubmit(e){
        e.preventDefault();
        FetchJSON('/password', {user: this.state.user_data}, 'PATCH').then(response => response.json()).then(data =>{
            if (data.success){
                this.setState({success: true,user_data_errors: {}})
                window.location = data.location;
            }else{
                this.setState(prev => {
                    prev.user_data_errors = data.errors
                    return prev;
                })
            }
         })
    }


    render() {
        return this.state.success ?
            (
                <Form className="login-section-container login-form">
                    <p>Password reset.  Logging you in...</p>
                </Form>
            )
            :
            (
                <Form className="login-section-container login-form" onSubmit={this.handleSubmit}>
                    <p>Choose a password. Passwords must be at least 6 characters long, and contain at least one number and
                        one special character.</p>
                    <Row><Column>
                        <InputField type="password" key="password" label="Password" value={this.state.user_data.password}
                                    error={this.state.user_data_errors.password} onChange={this.handlePasswordChange}/>
                    </Column></Row>
                    <Row><Column>
                        <InputField type="password" key="password_confirmation" label="Password Confirmation"
                                    value={this.state.user_data.password_confirmation}
                                    error={this.state.user_data_errors.password_confirmation}
                                    onChange={this.handlePasswordConfirmationChange}/>
                    </Column></Row>
                    <PasswordChecklist password={this.state.user_data.password} passwordConfirmation={this.state.user_data.password_confirmation}
                                       passwordValidityChange={this.handlePasswordValidityChange}/>
                    <div className="actions">
                        <Button className="button submit" value="Reset Password" disabled={!this.state.valid}/>
                    </div>
                </Form>
            )

    }
}

import ReactOnRails from 'react-on-rails';
import LandingBackgrounds from "new/components/unauthenticated/LandingBackgrounds";
import RegistrationForm from "new/components/unauthenticated/RegistrationForm";
import AccountForms from "new/components/unauthenticated/AccountForms";
import ResetPasswordForm from "new/components/unauthenticated/LoginForm";
import Banners from "new/styledcomponents/banners/Banners"

ReactOnRails.register({
    LandingBackgrounds,
    RegistrationForm,
    AccountForms,
    Banners,
    LoginForm: ResetPasswordForm
});

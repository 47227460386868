import React from 'react';
import {Form, InputField, Button, Row, Column} from "../forms/Form";
import {GoogleLoginLink,FacebookLoginLink} from "./SSO";
import autobind from "../../functions/autobind";
import {FetchJSON} from "../../functions/api_tools";
import {pick} from 'lodash'
import PasswordChecklist from "./PasswordChecklist";
import {TermsOfUse,PrivacyPolicy} from "./TermsAndPolicies";

export default class RegistrationForm extends React.Component {

    constructor(props) {
        super(props);
        autobind(this);
        this.state = {
            step: 0,
            privacyPolicyAccepted: false,
            termsAccepted: false,
            user_data: {email: this.props?.user?.email || '', first_name: this.props?.user?.first_name || '', last_name: this.props?.user?.last_name ||'', password: '', password_confirmation: ''},
            user_data_errors: {},
            password_valid: false
        };
    }

    componentDidMount() {
    }

    validate(fieldsToValidate){
        return FetchJSON('/validate', {user: this.state.user_data}).then(response => response.json()).then(data =>{
            if (data.valid){
                this.setState({user_data_errors: {}})
            }else{
                this.setState(prev => {
                    prev.user_data_errors = pick(data.errors, fieldsToValidate);
                    return prev;
                })
            }
            return Object.keys(this.state.user_data_errors).length === 0;
        })
    }

    stepFieldsToValidate = [
        ['email'],
        ['first_name','last_name'],
        ['password', 'password_confirmation']
    ]


    handleSubmit(e){
        e.preventDefault();
        let step = this.state.step;


        this.validate(this.stepFieldsToValidate[step]).then(valid => {
            if (valid){this.changeStep(step + 1)}
        });


    }

    async register(){
        return FetchJSON('/register', {user: this.state.user_data}).then(response => response.json()).then(data =>{
            if (data.success){
                this.setState({user_data_errors: {}, step: 3})
                this.props.registrationStepChanged(3);
            }
        })
    }

    async changeStep(step){
        if (step !== this.state.step){

            if (step === 3){
                await this.register();
                return;
            }

            this.props.registrationStepChanged(step);

            this.setState({step: step});
        }
    }

    setUserData(key,value){
        this.setState(prev =>{
            prev.user_data[key] = value;
            return prev;
        })
    }

    handleEmailChange = this.setUserData.bind(this,'email');
    handleFirstNameChange = this.setUserData.bind(this,'first_name');
    handleLastNameChange = this.setUserData.bind(this,'last_name');
    handlePasswordChange = this.setUserData.bind(this,'password');
    handlePasswordConfirmationChange = this.setUserData.bind(this,'password_confirmation');
    handlePasswordValidityChange(valid){
        this.setState({password_valid: valid})
    }

    goBack(){
        this.setState(prev => {
            return {step: prev.step - 1};
        })
    }

    render() {

        const backLink = <a className="register-back-button" onClick={this.goBack}>Back</a>

        return (
            <Form className="login-section-container login-form" onSubmit={this.handleSubmit}>

                {(() => {
                    switch (this.state.step) {
                        case 0:

                            return (
                                <>
                                    <Row><Column>
                                        <InputField key="email" type="email" label="Email" value={this.state.user_data.email} error={this.state.user_data_errors.email} onChange={this.handleEmailChange}/>
                                    </Column></Row>
                                    <TermsOfUse onChecked={checked => this.setState({termsAccepted: checked})}/>
                                    <PrivacyPolicy onChecked={checked => this.setState({privacyPolicyAccepted: checked})}/>
                                    <Row><Column><Button className="button submit" value="Next" disabled={!(this.state.privacyPolicyAccepted && this.state.termsAccepted)}/></Column></Row>
                                    <div className="login-link login-link-block" style={{margin_top:'10px'}}>
                                        <a onClick={this.props.loginClicked}>I Already Have An Account</a>
                                    </div>
                                </>
                            )

                        case 1:
                            return (
                                <>
                                    {backLink}
                                    <p>We need your name, so we can set up a quick profile, change this at any time.</p>
                                    <Row><Column>
                                        <InputField key="first_name" label="First Name" value={this.state.user_data.first_name} error={this.state.user_data_errors.first_name} onChange={this.handleFirstNameChange}/>
                                    </Column></Row>
                                    <Row><Column>
                                        <InputField key="last_name" label="Last Name" value={this.state.user_data.last_name} error={this.state.user_data_errors.last_name} onChange={this.handleLastNameChange}/>
                                    </Column></Row>
                                    <Row><Column><Button className="button submit" value="Next"/></Column></Row>
                                </>
                            )
                        case 2:
                            return (
                                <>
                                    {backLink}
                                    <p>Choose a password.  Passwords must be at least 6 characters long, and contain at least one number, and contain at least one upper case character, and one special character.</p>
                                    <Row><Column>
                                        <InputField type="password" key="password" label="Password" value={this.state.user_data.password} error={this.state.user_data_errors.password} onChange={this.handlePasswordChange}/>
                                    </Column></Row>
                                    <Row><Column>
                                        <InputField type="password" key="password_confirmation" label="Password Confirmation" value={this.state.user_data.password_confirmation} error={this.state.user_data_errors.password_confirmation} onChange={this.handlePasswordConfirmationChange}/>
                                    </Column></Row>
                                    <PasswordChecklist password={this.state.user_data.password} passwordConfirmation={this.state.user_data.password_confirmation} passwordValidityChange={this.handlePasswordValidityChange}/>
                                    <Row><Column><Button className="button submit" value="Finish" disabled={!this.state.password_valid}/></Column></Row>
                                </>
                            )
                        case 3:
                            return  <p>Registration Complete<br />Logging you in...</p>
                        default:
                            return null;
                    }
                })()}


            </Form>
        );
    }
}

import React from 'react';
import {GoogleLoginLink,FacebookLoginLink} from "./SSO";

export default class WelcomeForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
    }


    render() {

        return (
            <div style={{borderRadius:0}} className="login-section-container login-form welcome">

                {/*<FacebookLoginLink />*/}
                <GoogleLoginLink />

                <div className="login-divider">
                    <div className="line" />
                    Or
                    <div className="line" />
                </div>

                <div className="grid-buttons">
                    <a className="half-size" onClick={this.props.login}>
                        <div className="login-button generic">
                            Login
                        </div>
                    </a>
                    <a className="half-size" onClick={this.props.register}>
                        <div className="login-button generic">
                            Sign Up
                        </div>
                    </a>
                </div>
            </div>
        );
    }
}

import React, { useEffect, createRef, useState } from 'react';
import { Row, Column } from "./Form";
import * as Form from "new/styledcomponents/flexforms";
// import 'froala-editor/js/froala_editor.pkgd.min.js';
// import 'froala-editor/css/froala_editor.pkgd.min.css';
// import 'froala-editor/css/froala_style.min.css';
// import 'froala-editor/js/plugins.pkgd.min.js';
// import FroalaEditor from 'react-froala-wysiwyg';
// import styled from "styled-components";


// const FroalaEditorWrapper = styled(FroalaEditor)`
//     .fr-box .fr-basic {
//         width: 100%;
//     }
//
//     .fr-box .fr-toolbar .fr-btn-grp button svg {
//         height: 20px;
//     }
//
//     .fr-box .fr-toolbar .fr-btn-grp button fr-svg {
//         height: 20px;
//     }
// `


export const Wrap = ({layout, children}) => {
    switch (layout){
        case 'single':
            return (
                <Row>
                    <Column style={{width: '80%'}}>{children[0]}{children[1]}</Column>
                    {children[2] && <Column style={{marginTop: '20px'}}>{children[2]}</Column>}
                </Row>
            )
        case 'single-checkbox':
            return (
                <Row className="checkbox">
                    <Column style={{width: '80%'}}>{children[0]}{children[1]}</Column>
                    {children[2] && <Column style={{marginTop: '2px'}}>{children[2]}</Column>}
                </Row>
            )
        case 'column-only':
            return <Column>{children}</Column>
        default:
            return children;
    }
}


export const LabeledInput = ({ id, type, name, label, labelClass, fieldClass, labelPosition,
                                 layout, errors, register, labelProps, fieldProps, helpTip, setValue }) => {

    const [model, setModel] = useState(fieldProps && fieldProps.defaultValue ? fieldProps.defaultValue : '');

    const trixRef = createRef();
    const trixHiddenRef = createRef();

    const wrapTrixRef = (e) => {
        if (register) {
            register(e)
            trixHiddenRef.current = e
        }
    };

    const onBeforeInitialize = (ev) =>{
        if (fieldProps && fieldProps.onBeforeInitialize){
            fieldProps.onBeforeInitialize(ev);
        }
    }
    const onInitialize = (ev) =>{
        if (fieldProps && fieldProps.onInitialize){
            fieldProps.onInitialize(ev);
        }
    }

    const onBlur = (ev) =>{
        if (fieldProps && fieldProps.onBlur){
            fieldProps.onBlur(ev);
        }
    }
    const onFocus = (ev) =>{
        if (fieldProps && fieldProps.onFocus){
            fieldProps.onFocus(ev);
        }
    }

    const onAttachmentAdd = (ev) =>{
        if (fieldProps && fieldProps.onAttachmentAdd){
            fieldProps.onAttachmentAdd(ev);
        }
    }
    const onAttachmentRemove = (ev) =>{
        if (fieldProps && fieldProps.onAttachmentRemove){
            fieldProps.onAttachmentRemove(ev);
        }
    }

    const onChange = (ev) =>{
        if (fieldProps && fieldProps.onChange){
            fieldProps.onChange(ev);
        }
    }
    const onSelectionChange = (ev) =>{
        if (fieldProps && fieldProps.onSelectionChange){
            fieldProps.onSelectionChange(ev);
        }
    }
    const onFileAccepted = (ev) =>{
        if (fieldProps && fieldProps.onFileAccepted){
            fieldProps.onFileAccepted(ev);
        }
    }

    let errorClass = errors === undefined ? '' : 'error';
    let errorMessage = errors && (errors.type === "required" ? "is required" : errors.message);
    let labelField = <label {...labelProps} htmlFor={id} className={[labelClass, errorClass].join(' ')}>{[label, errorMessage].join(' ')}</label>
    let input;
    if (type === 'trix') {
        input = <>
            <input {...fieldProps} ref={wrapTrixRef} id={id} name={name} type="hidden"/>
            <trix-editor
                ref={trixRef} input={id} className='trix-content'
                style={{width: '100%', maxWidth: '100%', height: '100px', padding: 0, margin: 0, fontSize: '16px'}}
            />

            {/*<FroalaEditorWrapper*/}
            {/*    {...fieldProps}*/}
            {/*    tag='textarea'*/}
            {/*    model={model}*/}
            {/*    // ref={(ref) => {*/}
            {/*    //     if (ref && ref.editor && ref.editor.html) {*/}
            {/*    //         console.log('here2', ref.editor.html)*/}
            {/*    //         register(ref.editor.html);*/}
            {/*    //     }*/}
            {/*    //*/}
            {/*    // }}*/}
            {/*    onModelChange={(model) => {*/}
            {/*        setModel(model);*/}
            {/*        register && register(name);*/}
            {/*        setValue && setValue(name, model);*/}
            {/*    }}*/}
            {/*    config={{*/}
            {/*        attribution: false*/}
            {/*    }}*/}
            {/*/>*/}

        </>
    }
    else {
        input = <input {...fieldProps} ref={register} id={id} name={name} className={[fieldClass,errorClass].join(' ')} type={type || "text"} />
    }

    let helpTipComponent = helpTip ? <Form.HelpTip title={helpTip} /> : ""

    useEffect(() => {
        if (type === 'trix') {
            //FIXME: HACK - if register is defined set value, this was added for Trix and JSONForms.
            if (register) {
                trixRef.current.editor.insertHTML(trixRef.current.value);
            } else {
                trixRef.current.editor.insertHTML(fieldProps && fieldProps.defaultValue)
            }
            trixRef.current.addEventListener("trix-change",onChange);
            trixRef.current.addEventListener("trix-focus", onFocus);
            trixRef.current.addEventListener("trix-blur", onBlur);
            trixRef.current.addEventListener("trix-initialize", onInitialize);
            trixRef.current.addEventListener("trix-file-accept", onFileAccepted);
            trixRef.current.addEventListener("trix-attachment-add", onAttachmentAdd);
            trixRef.current.addEventListener("trix-attachment-remove", onAttachmentRemove);
            trixRef.current.addEventListener("trix-selection-change", onSelectionChange);
            trixRef.current.addEventListener("trix-before-initialize", onBeforeInitialize);
        }
    }, []);

    if (labelPosition === 'none'){
        return <Wrap layout={layout}>{input}{helpTipComponent}</Wrap>
    }else if (labelPosition === 'after'){
        return <Wrap layout={layout}>{input}{labelField}{helpTipComponent}</Wrap>
    }else{
        return <Wrap layout={layout}>{labelField}{input}{helpTipComponent}</Wrap>
    }


}

import React, {useEffect, useState} from "react";
import styled from 'styled-components';
import { toast } from 'react-toastify';
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {Collapse} from "@mui/material";

const BannerContainer = styled.div`
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    color: white;
    &.success{
        background-color: var(--color-success);
        color: #fff;
    }
    
    &.info{
        background-color: var(--color-deep-blue);
        color: #fff;
    }
    
    &.error{
        background-color: var(--color-error);
        color: #fff;
    }
    
    &.warning{
       background-color: var(--color-favorite);
       color: #fff;
    }

    .MuiCollapse-wrapperInner {
        display: flex;
        flex-direction: row;    
        justify-content: center;
        align-items: center;
    }
`

const BannerTitle = styled.div`
  text-decoration: none;
  font-weight: normal;
  font-size: 1.4rem;
  display: block;
  flex-flow: row nowrap;
  letter-spacing: 0.05rem;
  margin-right: 50px;
  margin-left: 50px;
  align-self: center;
  text-align: center;
  justify-content: center;
  &:before {
    height: 32px;
    width: 32px;
    background-size: 32px;
    background-repeat: no-repeat;
    background-position: center;
    // background-image: ${props => props.type === 'upcoming' ? `url("/assets/svg/error.svg");` : `url("/assets/svg/${props.type}.svg");`}
    background-color: ${props => `${props.bgColor}`};
    opacity: 1;
    transition: 0;
    content:"";
  }
`

const BannerLearnMore = styled.a`
  text-decoration: none;
  font-weight: bold;
  font-size: 1.4rem;
  display: flex;
  flex-flow: row nowrap;
  margin-right: 20px;
  letter-spacing: 0.05rem;
`

const BannerDetailsButton = styled.div`
    color: ${props => `${props.bgColor}`};
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: #fff;
    padding: 5px 20px;
    opacity: 1;
    // margin-left: 20px;
`

const BannerDismissButton = styled.div`
    color: #fff;
    cursor: pointer;
    border: 1px solid #fff;
    border-radius: 10px;
    background-color: ${props => `${props.bgColor}`};
    padding: 5px 30px;
    opacity: 1;
    margin-left: 20px;
`

const Banner = props => {
    const [showMore, setShowMore] = useState(false);
    const [bgColor, setBgColor] = useState("");
    const [subBgColor, setSubBgColor] = useState("");

    useEffect(() => {
        switch(props.type) {
            case "maintenance":
                setBgColor("#F2B64E");
                setSubBgColor("#DAA548");
                break;
            case "information":
                setBgColor("#81C95C");
                setSubBgColor("#72B052");
                break;
            case "upcoming":
                setBgColor("#E94A4A");
                setSubBgColor("#C73F3F");
                break;
            case "error":
                setBgColor("#E94A4A");
                setSubBgColor("#C73F3F");
                break;
            default:
                setBgColor("#F2B64E");
                break;
        }
    }, [])


    return (
        <>
            <BannerContainer key="banner" style={{backgroundColor: bgColor}}>
                {props.children && props.children}

                {!props.children &&
                    <>
                        <Collapse in={true}>
                            {props.image && <img style={{height: "32px", width:"32px"}} src={this.props.image} className={this.props.imageClass} />}
                            <BannerTitle bgColor={bgColor} type={props.type}>{props.title}</BannerTitle>
                            {props.url && <BannerLearnMore target="_blank" href={props.url} style={{color: '#fff', textDecoration: 'underline', marginLeft: '5px'}}>Learn more</BannerLearnMore>}
                            <BannerDetailsButton bgColor={bgColor} onClick={() => setShowMore(!showMore)}>{!showMore ? 'Show' : 'Hide'} Details</BannerDetailsButton>
                            {props.discardable && <BannerDismissButton bgColor={bgColor} onClick={() => props.discard && props.discard(props.id)}>Dismiss</BannerDismissButton>}
                        </Collapse>

                        <Collapse in={showMore} style={{backgroundColor: subBgColor, textAlign: 'center'}}>
                            {props.description && <div dangerouslySetInnerHTML={{__html:props.description}}/>}
                        </Collapse>
                    </>

                }
            </BannerContainer>
        </>
    )

};

export default Banner;
